// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-edusmarty-js": () => import("./../../../src/pages/courses/edusmarty.js" /* webpackChunkName: "component---src-pages-courses-edusmarty-js" */),
  "component---src-pages-courses-handwriting-js": () => import("./../../../src/pages/courses/handwriting.js" /* webpackChunkName: "component---src-pages-courses-handwriting-js" */),
  "component---src-pages-courses-hubspot-hubspot-contact-form-js": () => import("./../../../src/pages/courses/hubspot/HubspotContactForm.js" /* webpackChunkName: "component---src-pages-courses-hubspot-hubspot-contact-form-js" */),
  "component---src-pages-courses-jolly-js": () => import("./../../../src/pages/courses/jolly.js" /* webpackChunkName: "component---src-pages-courses-jolly-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-courses-preschool-curriculum-js": () => import("./../../../src/pages/courses/preschool-curriculum.js" /* webpackChunkName: "component---src-pages-courses-preschool-curriculum-js" */),
  "component---src-pages-courses-preschool-needs-js": () => import("./../../../src/pages/courses/preschool-needs.js" /* webpackChunkName: "component---src-pages-courses-preschool-needs-js" */),
  "component---src-pages-courses-professional-development-js": () => import("./../../../src/pages/courses/professional-development.js" /* webpackChunkName: "component---src-pages-courses-professional-development-js" */),
  "component---src-pages-courses-teacher-training-js": () => import("./../../../src/pages/courses/teacher-training.js" /* webpackChunkName: "component---src-pages-courses-teacher-training-js" */),
  "component---src-pages-edugames-js": () => import("./../../../src/pages/edugames.js" /* webpackChunkName: "component---src-pages-edugames-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-teachers-anagha-js": () => import("./../../../src/pages/teachers/anagha.js" /* webpackChunkName: "component---src-pages-teachers-anagha-js" */),
  "component---src-pages-teachers-hiral-js": () => import("./../../../src/pages/teachers/hiral.js" /* webpackChunkName: "component---src-pages-teachers-hiral-js" */),
  "component---src-pages-teachers-jinal-js": () => import("./../../../src/pages/teachers/jinal.js" /* webpackChunkName: "component---src-pages-teachers-jinal-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-teachers-neeti-js": () => import("./../../../src/pages/teachers/neeti.js" /* webpackChunkName: "component---src-pages-teachers-neeti-js" */),
  "component---src-pages-teachers-nikita-js": () => import("./../../../src/pages/teachers/nikita.js" /* webpackChunkName: "component---src-pages-teachers-nikita-js" */),
  "component---src-pages-teachers-ruchi-js": () => import("./../../../src/pages/teachers/ruchi.js" /* webpackChunkName: "component---src-pages-teachers-ruchi-js" */)
}

